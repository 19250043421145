import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Company from "../models/company";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../../core/feature/store";
import { removeToken } from "../../core/feature/token/tokenSlice";
import { ValidateError, ValidateSuccess } from "./interceptor_error_message";
// import { Navigate, useNavigate } from "react-router-dom";
import { removeUser } from "../feature/user/userSlice";
import { setShowOtp } from "../feature/config/configSlice";
import { requestOtpLogin } from "./api";
import { setOtpData } from "../feature/otp/otpSlice";

const reduxState = store.getState();

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.log(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
  // let message = "";
  // if (response && response.data) {
  //   const data = response.data as any
  //   // console.log(response.data.type)
  //   message = await ValidateSuccess(data)
  //   // console.log(response)
  //   // if (data) message = await ValidateSuccess(data)
  //   if (response.config.url.includes("authentication/admin/login") || response.config.url.includes("authentication/private/admin/token")) {

  //   } else {

  //     toast.success(message)
  //   }
  // }
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let message = "";
  let data = error.response?.data as any;
  let detail = data?.detail;
  if (error.response && error.response.data) {
    // const data = error.response?.data as any
    if (error.request.responseType == "blob") {
      const blob = new Blob([error.response.data as any]);
      const data = await blob.text();
      detail = JSON.parse(data).detail;
      message = ValidateError(JSON.parse(data), error.config);
    } else {
      message = ValidateError(data, error.config);
    }
    if (
      detail == "Not authenticated" ||
      detail?.message == "Not authenticated"
    ) {
      store.dispatch(removeToken());
      store.dispatch(removeUser());
      message = "Silahkan Login Terlebih Dahulu";
      setTimeout(() => {
        window.location.href = `/${JSON.parse(
          localStorage.getItem("pathPrefix")
        )}`;
      }, 1500);
    }

    if ([422].includes(error.response?.status as number)) {
      message = "Terjadi Kesalahan, Data Tidak Dapat Diproses";
    }

    if ([405].includes(error.response?.status as number)) {
      message = "Metode Tidak Sesuai";
    }

    if ([401].includes(error.response?.status as number)) {
      store.dispatch(removeToken());
      store.dispatch(removeUser());
      // toast.error(message)
      setTimeout(() => {
        window.location.href = `/${JSON.parse(
          localStorage.getItem("pathPrefix")
        )}`;
      }, 1500);
    }
    // toast.error(message);
    if ([502, 503, 500].includes(error.response?.status as number)) {
      message = "Terjadi Kesalahan Sistem, silahkan coba beberapa saat lagi";
    }

    const type = data.detail?.type || "";
    if (
      [403].includes(error.response?.status as number) &&
      ["OTP_REQUIRED", "OTP_NOT_FOUND"].includes(type)
    ) {
      let payloadData: any = error.response.config.data;
      store.dispatch(setOtpData(JSON.parse(payloadData)));
      let transactionType = store.getState().otp.transactionType;
      if (transactionType == "NewDevice") {
        let username = store.getState().otp.username;
        let data = {
          username: username,
          type: "EMAIL",
        };
        requestOtpLogin(data)
          .then((res) => {
            // setTimer(Date.now() + 3000);
            // console.log(res);
            store.dispatch(setShowOtp(true));
          })
          .catch((err) => {});
      }
      if (["CREATE_ADMIN", "UPDATE_ADMIN"].includes(transactionType)) {
      }
    }
    if (detail?.type == "TEMPLATE_COMPANY_MESSAGE_NOT_FOUND") {
      toast.warning(
        "Company belum memiliki template tersendiri. Silahkan mulai kustom template notifikasi Anda"
      );
    } else {
      toast.error(message);
    }
  }
  if (error.response?.status == 500) {
    toast.error("Terjadi Kesalahan Sistem, silahkan coba beberapa saat lagi");
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
