import { cilMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CButton,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageIcon from "src/assets/images/Iconly/Light-Outline/Image.svg";
import { setLoading } from "src/core/feature/config/configSlice";
import { removeFinancialType } from "src/core/feature/financialType/financialType";
import { useAppSelector } from "src/core/feature/hooks";
import { removeMenu } from "src/core/feature/menu/menuSlice";
import { setSidebarShow } from "src/core/feature/template/templateSlice";
import { setToken } from "src/core/feature/token/tokenSlice";
import { setUser } from "src/core/feature/user/userSlice";
import * as API from "../core/services/api";
import { AppHeaderDropdown } from "./header/index";

const AppHeader = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.profile.profile);
  const financialType = useAppSelector(
    (state) => state.financialType.financialType
  );
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  let navigate = useNavigate();

  const changeCompany = () => {
    dispatch(setLoading(true));
    API.changeCompany()
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(removeMenu());
        dispatch(removeFinancialType());
        dispatch(setUser(response.data.data));
        dispatch(setToken(response.data.data.authToken));
        localStorage.removeItem("show_menu_admin");
        toast.success("Berhasil");
        localStorage.removeItem("version");
        window.location.replace("/dashboard-monitor");
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}
        <CHeaderNav className="d-none d-md-flex me-auto">
          {user?.companyId ? (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              {user?.companyName}
            </div>
          ) : (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              SUPERADMIN
            </div>
          )}

          {/* <CNavItem></CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          {user?.companyId && user?.userType == "SUPERADMIN" ? (
            <CButton
              color=""
              type="button"
              size="sm"
              style={{
                background: "#246BFD",
                color: "#fff",
                borderRadius: "8px",
              }}
              onClick={() => {
                changeCompany();
              }}
            >
              {" "}
              Kembali ke Superadmin
            </CButton>
          ) : (
            ""
          )}
          {user?.companyId &&
          JSON.parse(localStorage.getItem("version")) == "pro" ? (
            <CButton
              color=""
              type="button"
              size="sm"
              style={{
                background: "#2298CA",
                color: "#fff",
                borderRadius: "8px",
                marginLeft: "2px",
              }}
              onClick={() => {
                localStorage.setItem("version", JSON.stringify("lite"));
                window.location.replace("/main/company-info");
              }}
            >
              {" "}
              Pindah ke Lite Version
            </CButton>
          ) : null}
          {user?.companyId && financialType ? (
            <CNavItem>
              <CButton
                color=""
                type="button"
                size="sm"
                style={{
                  background:
                    financialType == "switcher" ? "#22CAAD" : "#F86624",
                  color: "#fff",
                  marginLeft: "2px",
                  borderRadius: "8px",
                }}
              >
                {financialType?.toUpperCase()}
              </CButton>
            </CNavItem>
          ) : null}
          {/* {localStorage.getItem("show_menu_admin") !=
            JSON.stringify("data_utama") ? (
            <CNavItem>
              <CButton
                color=""
                type="button"
                size="sm"
                style={{
                  background: "#032E9A",
                  color: "#fff",
                  marginLeft: "2px",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "show_menu_admin",
                    `${JSON.stringify("data_utama")}`
                  );
                  // );
                  window.location.replace("/main/company-info");
                }}
              >
                Dashboard
              </CButton>
            </CNavItem>
          ) : null} */}
        </CHeaderNav>
        {/* {user?.companyId && user?.userType == "ADMIN" ? (
          <NavLink to="/notification" className="ms-3 text-decoration">
            <FontAwesomeIcon
              icon={faBell}
              className="me-2"
              style={{ fontSize: "1.5rem" }}
            />
          </NavLink>
        ) : (
          ""
        )} */}

        <CHeaderNav className="ms-3">
          <CAvatar
            color=""
            status="success"
            src={profile || ImageIcon}
            size="md"
            style={{ width: "36px" }}
          />
          <div className="py-1" style={{ marginLeft: ".2rem" }}>
            <div style={{ fontSize: ".855rem" }}>
              <b>{user?.name}</b>
            </div>
            <div style={{ fontSize: ".700rem" }} className="text-muted">
              {user?.userType}
            </div>
          </div>
          <AppHeaderDropdown></AppHeaderDropdown>
        </CHeaderNav>
      </CContainer>

      {/* <CContainer fluid>
        <div style={{ marginTop: "1rem", color: "#A3AED0" }}>
          Hi, {user?.name}
        </div>
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
