import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import Icons from "src/config/icon_menu";

const _nav = [
  {
    component: CNavTitle,
    name: "MENU UTAMA",
    permission: "all",
  },
  // {
  //   component: CNavItem,
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: Icons.sideBar.dashboard,
  //   permission: "all",
  // },
  {
    component: CNavItem,
    name: "Info Perusahaan",
    to: "/main/company-info",
    icon: Icons.sideBar.infoPerusahaan,
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Pengelolaan Keuangan",
    to: "/financial-management",
    icon: Icons.sideBar.pengelolaanKeuangan,
    permission: "all",
  },

  {
    component: CNavTitle,
    name: "KEANGGOTAAN & INFORMASI",
    permission: "all",
  },
  {
    component: CNavGroup,
    name: "data_user",
    icon: Icons.sideBar.dataUser,
    permission: ["data_user"],
    items: [
      {
        component: CNavItem,
        name: "User Admin",
        permission: "data_user",
        to: "/admin",
      },

      {
        component: CNavGroup,
        name: "User Member",
        permission: ["data_user"],
        items: [
          {
            component: CNavItem,
            name: "User Member",
            permission: "data_user",
            to: "/user/member",
          },
          {
            component: CNavItem,
            name: "Konfig User Member",
            permission: "data_user",
            to: "/user/member/config",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "User Merchant",
        permission: ["data_user"],
        items: [
          {
            component: CNavItem,
            name: "User Merchant",
            permission: "data_user",
            to: "/user/merchant",
          },
          {
            component: CNavItem,
            name: "Konfig User Merchant",
            permission: "data_user",
            to: "/user/merchant/config",
          },
        ],
      },
    ],
  },
  {
    component: CNavGroup,
    name: "manajemen_siakad",
    icon: Icons.sideBar.lms,
    permission: ["manajemen_siakad"],
    // to: "/siakad-management",
    items: [
      {
        component: CNavItem,
        name: "Manajemen User",
        permission: "manajemen_siakad",
        to: "/siakad-management/user",
      },
      {
        component: CNavItem,
        name: "Riwayat Perizinan",
        permission: "manajemen_siakad",
        to: "/siakad-management/permission-history",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Data Notifikasi",
    icon: Icons.sideBar.dataNotifikasi,
    to: "/notification-management/record-notification",
    permission: "data_notifikasi",
  },
  {
    component: CNavItem,
    name: "info_dan_berita",
    to: "/news-info",
    icon: Icons.sideBar.infoBerita,
    permission: "info_dan_berita",
  },
  {
    component: CNavGroup,
    name: "Sub Company",
    icon: Icons.sideBar.subCompany,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Data Sub Company",
        to: "/subCompany",
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Data Admin",
        to: "/subCompany-admin",
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Data Member",
        to: "/subCompany-member",
        permission: "all",
      },
    ],
  },
  {
    component: CNavTitle,
    name: "MANAJEMEN TRANSAKSI",
    permission: "all",
  },
  {
    component: CNavGroup,
    name: "saldo_dan_pembayaran",
    icon: Icons.sideBar.saldoPembayaran,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Akun Bank Perusahaan",
        to: "/balance-payment/account-bank",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Konfigurasi Saldo Ganda",
        to: "/balance-payment/config-balance",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Ketentuan Checkout Link",
        to: "/balance-payment/config-checkout",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Saldo",
        to: "/transaction/balance",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Mutasi",
        to: "/transaction/mutation",
        permission: "saldo_dan_pembayaran",
      },
      // {
      //   component: CNavItem,
      //   name: "Pencairan Dana Manual",
      //   to: "/balance-payment/manual-disbursement",
      //   permission: "saldo_dan_pembayaran",
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Top Up Virtual Account (VA)",
    icon: Icons.sideBar.topupVa,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/topup-va",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Top Up VA",
        to: "/transaction/topup-data",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Transfer Bank",
    icon: Icons.sideBar.transferBank,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/transfer-bank",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Transfer Bank",
        to: "/balance-payment/transaction/transfer-bank",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Withdraw Merchant",
    icon: Icons.sideBar.withdrawMerchant,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/withdraw-merchant",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Withdraw Merchant",
        to: "/transaction/merchant-withdrawal-transaction-data",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Transfer Member",
    icon: Icons.sideBar.transferMember,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/transfer-member",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Transfer Member",
        to: "/balance-payment/transaction/transfer-member",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Transaksi QRIS",
    icon: Icons.sideBar.trxQris,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/qris",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi QRIS",
        to: "/balance-payment/transaction/qris",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Transaksi Barcode",
    icon: Icons.sideBar.trxBarcode,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/qrcode",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Barcode",
        to: "/balance-payment/transaction/barcode",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Top Up Member via Merchant",
    icon: Icons.sideBar.topupMemberViaMerchant,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/topup-merchant",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Top Up via Merchant",
        to: "/balance-payment/transaction/topup-va-merchant",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Top Up User Manual",
    icon: Icons.sideBar.topupUserManual,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/topup-admin",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Top Up Manual",
        to: "/balance-payment/transaction/topup-admin",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Pencairan User Manual",
    icon: Icons.sideBar.pencairanUserManual,
    permission: ["saldo_dan_pembayaran"],
    items: [
      {
        component: CNavItem,
        name: "Penarikan",
        to: "/balance-payment/finance-menu",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Ketentuan Biaya Admin",
        to: "/balance-payment/config/withdraw-admin",
        permission: "saldo_dan_pembayaran",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Pencairan Manual",
        to: "/balance-payment/transaction/disbursement-admin",
        permission: "saldo_dan_pembayaran",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Data Log VA",
    to: "/log-va",
    icon: Icons.sideBar.dataLogVa,
    permission: "saldo_dan_pembayaran",
  },
  {
    component: CNavGroup,
    name: "invoice",
    icon: Icons.sideBar.tagihan,
    permission: ["invoice"],
    items: [
      {
        component: CNavItem,
        name: "Master Invoice",
        to: "/invoice/master-invoice",
        permission: "invoice",
      },
      {
        component: CNavItem,
        name: "Invoice Tunggal",
        to: "/invoice/invoice-tunggal",
        permission: "invoice",
      },
      {
        component: CNavGroup,
        name: "Data Invoice",
        permission: ["invoice"],
        items: [
          {
            component: CNavItem,
            name: "Invoice Admin",
            permission: "invoice",
            to: "/invoice/invoice-admin",
          },
          {
            component: CNavItem,
            name: "Invoice Member",
            permission: "invoice",
            to: "/invoice/invoice-member",
          },
          {
            component: CNavItem,
            name: "Invoice Merchant",
            permission: "invoice",
            to: "/invoice/invoice-merchant",
          },
        ],
      },
      {
        component: CNavItem,
        name: "Data Transaksi Invoice",
        to: "/invoice/data-transaksi-invoice",
        permission: "invoice",
      },
      // {
      //   component: CNavItem,
      //   name: "Pengaturan Integrasi",
      //   to: "/invoice/pengaturan-integrasi",
      //   permission: "invoice",
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "digital_produk_ppob",
    icon: Icons.sideBar.digitalProduk,
    permission: ["digital_produk_ppob"],
    items: [
      {
        component: CNavItem,
        name: "Biaya Admin PPOB",
        permission: "digital_produk_ppob",
        to: "/ppob/charge-config",
      },
      {
        component: CNavItem,
        name: "Data Transaksi PPOB",
        permission: "digital_produk_ppob",
        to: "/ppob/transaction-data",
      },
      {
        component: CNavItem,
        name: "Riwayat Pembelian PPOB",
        permission: "digital_produk_ppob",
        to: "/transaction/ppob/purchase-history",
      },
      {
        component: CNavItem,
        name: "Data Keuangan PPOB",
        permission: "switcher",
        to: "/ppob/financial-data",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "marketplace_dan_sistem_kasir",
    icon: Icons.sideBar.tokoOnline,
    permission: ["marketplace_dan_sistem_kasir"],
    items: [
      {
        component: CNavGroup,
        name: "Data Marketplace",
        permission: ["marketplace_dan_sistem_kasir"],
        items: [
          {
            component: CNavItem,
            name: "Aktivasi Merchant",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/merchant-activation",
          },
          {
            component: CNavItem,
            name: "Aktivasi Order Receiver",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/cashier/order-receiver",
          },
          {
            component: CNavItem,
            name: "Ketentuan dan Kustomisasi Marketplace",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/provisions-customization",
          },
          // {
          //   component: CNavItem,
          //   name: "Menu dan Penamaan",
          //   permission: "marketplace_dan_sistem_kasir",
          //   to: "/marketplace-cashier/marketplace/menu-naming",
          // },
          // {
          //   component: CNavItem,
          //   name: "Konfigurasi Formulir Order",
          //   permission: "marketplace_dan_sistem_kasir",
          //   to: "/marketplace-cashier/marketplace/order-form",
          // },
          // {
          //   component: CNavItem,
          //   name: "Konfigurasi Auto-Confirmed",
          //   permission: "marketplace_dan_sistem_kasir",
          //   to: "/marketplace-cashier/marketplace/auto-confirm",
          // },
          {
            component: CNavItem,
            name: "Biaya Admin Marketplace",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/order-fees",
          },
          {
            component: CNavItem,
            name: "Data Kategori",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/category",
          },
          {
            component: CNavItem,
            name: "Data Produk",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/product",
          },
          {
            component: CNavItem,
            name: "Data Order dan Transaksi",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/marketplace/transactions-orders",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Data Kasir",
        permission: ["marketplace_dan_sistem_kasir"],
        items: [
          {
            component: CNavItem,
            name: "Biaya Transaksi Kasir",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/cashier/transaction-fees",
          },
          {
            component: CNavItem,
            name: "Data Kategori",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/cashier/category",
          },
          {
            component: CNavItem,
            name: "Data Produk",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/cashier/product",
          },
          {
            component: CNavItem,
            name: "Data Transaksi Kasir",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/cashier/transactions",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "FnB",
        permission: ["marketplace_dan_sistem_kasir"],
        items: [
          {
            component: CNavItem,
            name: "Data Order dan Transaksi FnB",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/fnb/order-data",
          },
          {
            component: CNavItem,
            name: "Ketentuan Pembayaran",
            permission: "marketplace_dan_sistem_kasir",
            to: "/marketplace-cashier/fnb/merchant-payment-config",
          },
        ],
      },
      // {
      //   component: CNavItem,
      //   name: "Properties Menu",
      //   permission: "marketplace_dan_sistem_kasir",
      //   to: "/marketplace-cashier/properties-menu",
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "donasi_dan_zakat",
    icon: Icons.sideBar.donasi,
    permission: ["donasi_dan_zakat"],
    items: [
      {
        component: CNavItem,
        name: "Data Donasi",
        permission: "donasi_dan_zakat",
        to: "/donation/donation",
      },
      {
        component: CNavItem,
        name: "Data Zakat",
        permission: "donasi_dan_zakat",
        to: "/donation/zakat",
      },
      // {
      //   component: CNavItem,
      //   name: "Properties Menu",
      //   permission: "donasi_dan_zakat",
      //   to: "/donation/properties-menu",
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "manajemen_kartu",
    icon: Icons.sideBar.manajemenKartu,
    permission: ["manajemen_kartu"],
    items: [
      {
        component: CNavItem,
        name: "Ketentuan Kartu",
        to: "/card-config",
        permission: "manajemen_kartu",
      },
      {
        component: CNavItem,
        name: "Kustom Data Kartu",
        to: "/card-member-config",
        permission: "manajemen_kartu",
      },
      {
        component: CNavItem,
        name: "Kustom Template Kartu",
        to: "/card/card-template",
        permission: "manajemen_kartu",
      },
      {
        component: CNavItem,
        name: "Biaya Admin Kartu",
        to: "/card/card-admin-fees",
        permission: "manajemen_kartu",
      },
      // {
      //   component: CNavGroup,
      //   name: "Konfigurasi Biaya Kartu",
      //   // to: '/card-template',
      //   permission: ["manajemen_kartu"],
      //   items: [
      //     {
      //       component: CNavItem,
      //       name: "Harga Pembuatan Kartu",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-price",
      //     },
      //     {
      //       component: CNavItem,
      //       name: "Biaya Transaksi Top Up Saldo Kartu Member",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-balance-topup-member",
      //     },
      //     {
      //       component: CNavItem,
      //       name: "Biaya Transaksi Top Up Saldo Kartu Via Merchant",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-balance-topup-merchant",
      //     },
      //     {
      //       component: CNavItem,
      //       name: "Biaya Transaksi Withdraw Saldo Kartu Member",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-balance-withdraw-member",
      //     },
      //     {
      //       component: CNavItem,
      //       name: "Biaya Transaksi Withdraw Saldo Kartu Via Merchant",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-balance-withdraw-merchant",
      //     },
      //     {
      //       component: CNavItem,
      //       name: "Biaya Transaksi Kartu",
      //       permission: "manajemen_kartu",
      //       to: "/config-charge/card-transaction",
      //     },
      //   ],
      // },
      {
        component: CNavItem,
        name: "Data Kartu Virtual",
        to: "/card-data",
        permission: "manajemen_kartu",
      },
      {
        component: CNavItem,
        name: "Data Transaksi Kartu",
        to: "/card-summary-transaction",
        permission: "manajemen_kartu",
      },
      {
        component: CNavGroup,
        name: "Manajemen Distribusi Bantuan",
        permission: ["manajemen_kartu"],
        items: [
          {
            component: CNavItem,
            name: "Data Bantuan",
            permission: "manajemen_kartu",
            to: "/card/distribution-aid/data",
          },
          {
            component: CNavItem,
            name: "Data Penerima Bantuan (non member)",
            permission: "manajemen_kartu",
            to: "/card/distribution-aid/aid-recipients",
          },
        ],
      },
    ],
  },

  {
    component: CNavTitle,
    name: "INTEGRASI & AKTIVITAS LAIN",
    permission: "manajemen_integrasi",
  },
  {
    component: CNavGroup,
    name: "manajemen_integrasi",
    icon: Icons.sideBar.manajemenIntegrasi,
    permission: ["manajemen_integrasi"],
    items: [
      {
        component: CNavItem,
        name: "Pengaturan Credential",
        permission: "manajemen_integrasi",
        to: "/integration-management/credential-settings",
      },
      {
        component: CNavItem,
        name: "Riwayat Callback",
        permission: "manajemen_integrasi",
        to: "/integration-management/history",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "manajemen_accessibility",
    icon: Icons.sideBar.manajemenAkses,
    permission: ["manajemen_accessibility"],
    items: [
      {
        component: CNavItem,
        name: "Daftar Perangkat",
        permission: "manajemen_accessibility",
        to: "/accessibility-management/device",
      },
      {
        component: CNavGroup,
        name: "Absensi",
        permission: ["manajemen_accessibility"],
        items: [
          {
            component: CNavItem,
            name: "Kelola Aktivitas Absensi",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/attendance/activity",
          },
          {
            component: CNavItem,
            name: "Pendaftaran Wajah",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/attendance/data-train",
          },
          {
            component: CNavItem,
            name: "Riwayat Absensi Pengguna",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/attendance/history",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Akses",
        permission: ["manajemen_accessibility"],
        items: [
          {
            component: CNavItem,
            name: "Kelola Aktivitas Akses",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/entrance/activity",
          },
          {
            component: CNavItem,
            name: "Riwayat Akses Pengguna",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/entrance/history",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Akses payGate",
        permission: ["manajemen_accessibility"],
        items: [
          {
            component: CNavItem,
            name: "Kelola Aktivitas payGate",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/paygate/activity",
          },
          {
            component: CNavItem,
            name: "Riwayat payGate",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/paygate/history",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Parkir",
        permission: ["manajemen_accessibility"],
        items: [
          {
            component: CNavItem,
            name: "Kelola Aktivitas Parkir",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/parking/activity",
          },
          {
            component: CNavItem,
            name: "Riwayat Parkir",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/parking/history",
          },
          {
            component: CNavItem,
            name: "Subscription",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/parking/subscription",
          },
        ],
      },
      // {
      //   component: CNavItem,
      //   name: "Properties Menu",
      //   permission: "manajemen_accessibility",
      //   to: "/accessibility-management/properties-menu",
      // },
    ],
  },

  {
    component: CNavTitle,
    name: "KONFIGURASI TAMPILAN",
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Properties dan Menu Utama",
    icon: Icons.sideBar.propertiesMenu,
    to: "/properties-main-menu",
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Konfigurasi Sub Menu",
    icon: Icons.sideBar.subMenu,
    to: "/submenu-configuration",
    permission: "all",
  },
  {
    component: CNavItem,
    name: "Konfigurasi Template Notifikasi",
    icon: Icons.sideBar.konfigNotifikasiTemplate,
    to: "/notification-management/template-configuration",
    permission: "data_notifikasi",
  },

  // {
  //   component: CNavGroup,
  //   name: "data_utama",
  //   icon: <CIcon icon={cilClone} customClassName="nav-icon" />,
  //   permission: ["data_utama"],
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "Info Perusahaan",
  //       permission: "data_utama",
  //       to: "/main/company-info",
  //     },
  //     {
  //       component: CNavGroup,
  //       name: "Konfigurasi",
  //       permission: ["data_utama"],
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: "Properties Perusahaan",
  //           permission: "data_utama",
  //           to: "/main/company-properties",
  //         },
  //         {
  //           component: CNavItem,
  //           name: "Registrasi Mandiri",
  //           permission: "data_utama",
  //           to: "/main/self-register",
  //         },
  //         {
  //           component: CNavItem,
  //           name: "Menu Member",
  //           permission: "data_utama",
  //           to: "/main/menu-member",
  //         },
  //         {
  //           component: CNavItem,
  //           name: "Menu Merchant",
  //           permission: "data_utama",
  //           to: "/main/menu-merchant",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default _nav;
