import React from "react";
const siakadManagement = React.lazy(() =>
  import("../views/pages/siakad/userManagement")
);
const propertiesMenuLms = React.lazy(() =>
  import("../views/pages/siakad/configMenuLms")
);
const permissionHistory = React.lazy(() =>
  import("../views/pages/siakad/permissionHistory")
);

const academicRoutes = [
  {
    path: "/siakad-management/user",
    element: siakadManagement,
  },

  {
    path: "/siakad-management/properties-menu",
    element: propertiesMenuLms,
  },
  {
    path: "/siakad-management/permission-history",
    element: permissionHistory,
  },
];

export default academicRoutes;
