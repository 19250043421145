import React from "react";
const Admin = React.lazy(() => import("../views/pages/admin/Admin"));
const newAdmin = React.lazy(() => import("../views/pages/admin/New"));
const editAdmin = React.lazy(() => import("../views/pages/admin/Edit"));
const roleAdmin = React.lazy(() => import("../views/pages/admin/Role"));
const newRoleAdmin = React.lazy(() => import("../views/pages/admin/newRole"));
const editRoleAdmin = React.lazy(() => import("../views/pages/admin/editRole"));

const Member = React.lazy(() => import("../views/pages/member/Member"));
const newMember = React.lazy(() => import("../views/pages/member/New"));
const editMember = React.lazy(() => import("../views/pages/member/Edit"));
const roleMember = React.lazy(() => import("../views/pages/member/Role"));
const newRoleMember = React.lazy(() => import("../views/pages/member/newRole"));
const editRoleMember = React.lazy(() =>
  import("../views/pages/member/editRole")
);

const Merchant = React.lazy(() => import("../views/pages/merchant/Merchant"));
const newMerchant = React.lazy(() => import("../views/pages/merchant/New"));
const editMerchant = React.lazy(() => import("../views/pages/merchant/Edit"));
const roleMerchant = React.lazy(() => import("../views/pages/merchant/Role"));
const newRoleMerchant = React.lazy(() =>
  import("../views/pages/merchant/newRole")
);
const editRoleMerchant = React.lazy(() =>
  import("../views/pages/merchant/editRole")
);
const tagsMember = React.lazy(() => import("../views/pages/member/tags/Tags"));
const accountBankMerchant = React.lazy(() =>
  import("../views/pages/merchant/accountBank/accountBank")
);
const memberDataConfig = React.lazy(() =>
  import("../views/pages/member/config/configData")
);
const settingMemberDataConfig = React.lazy(() =>
  import("../views/pages/member/config/settingData")
);
const merchantDataConfig = React.lazy(() =>
  import("../views/pages/merchant/config/configData")
);
const settingMerchantDataConfig = React.lazy(() =>
  import("../views/pages/merchant/config/settingData")
);
const subCompany = React.lazy(() =>
  import("../views/pages/subCompany/subCompany")
);
const adminSubCompany = React.lazy(() =>
  import("../views/pages/subCompany/adminSubCompany")
);
const memberSubCompany = React.lazy(() =>
  import("../views/pages/subCompany/memberSubCompany")
);
const parentMember = React.lazy(() =>
  import("../views/pages/member/parent/ParentMember")
);
const newParentMember = React.lazy(() =>
  import("../views/pages/member/parent/NewParent")
);
const EditParentMember = React.lazy(() =>
  import("../views/pages/member/parent/EditParent")
);

const userRoutes = [
  { path: "/admin", name: "Data Admin", element: Admin },
  { path: "/admin/new", name: "Tambah Data", element: newAdmin },
  { path: "/admin/edit/:id", name: "Edit Data", element: editAdmin },
  { path: "/role-admin", name: "Role Admin", element: roleAdmin },
  { path: "/role-admin/new", name: "Tambah Data", element: newRoleAdmin },
  { path: "/role-admin/edit/:id", name: "Edit Data", element: editRoleAdmin },

  { path: "/user/member", name: "Data Member", element: Member },
  { path: "/user/member/new", name: "Tambah Data", element: newMember },
  { path: "/user/member/edit/:id", name: "Edit Data", element: editMember },
  {
    path: "/user/member/management-role",
    name: "Role Member",
    element: roleMember,
  },
  {
    path: "/user/member/management-role/new",
    name: "Tambah Data",
    element: newRoleMember,
  },
  {
    path: "/user/member/management-role/edit/:id",
    name: "Edit Data",
    element: editRoleMember,
  },

  { path: "/user/merchant", name: "Data Merchant", element: Merchant },
  { path: "/user/merchant/new", name: "Tambah Data", element: newMerchant },
  { path: "/user/merchant/edit/:id", name: "Edit Data", element: editMerchant },
  {
    path: "/user/merchant/management-role",
    name: "Role Merchant",
    element: roleMerchant,
  },
  {
    path: "/user/merchant/management-role/new",
    name: "Tambah Data",
    element: newRoleMerchant,
  },
  {
    path: "/user/merchant/management-role/edit/:id",
    name: "Edit Data",
    element: editRoleMerchant,
  },
  {
    path: "/user/member/tags",
    element: tagsMember,
  },
  { path: "/user/merchant/account-bank/:id", element: accountBankMerchant },
  {
    path: "/user/member/config",
    element: memberDataConfig,
  },
  {
    path: "/user/member/config/setting",
    element: settingMemberDataConfig,
  },
  {
    path: "/user/merchant/config",
    element: merchantDataConfig,
  },
  {
    path: "/user/merchant/config/setting",
    element: settingMerchantDataConfig,
  },
  {
    path: "/subCompany",
    element: subCompany,
  },
  {
    path: "/subCompany-admin",
    element: adminSubCompany,
  },
  {
    path: "/subCompany-member",
    element: memberSubCompany,
  },
  {
    path: "/user/member/parent-member",
    element: parentMember,
  },
  {
    path: "/user/member/parent-member/new",
    element: newParentMember,
  },
  {
    path: "/user/member/parent-member/edit/:id",
    element: EditParentMember,
  },
];

export default userRoutes;
