import React from "react";
const listDevice = React.lazy(() =>
  import("../views/pages/accessibilityManagement/device/device")
);
const detailDataDevice = React.lazy(() =>
  import("../views/pages/accessibilityManagement/device/detailData")
);
const activityAttendance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/attendance/activity/activity")
);
const NewAttendance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/attendance/activity/New")
);
const EditAttendance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/attendance/activity/Edit")
);
const DetailAttendance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/attendance/activity/Detail")
);
const HistoryAbsentUser = React.lazy(() =>
  import("../views/pages/accessibilityManagement/attendance/history/history")
);
const ActivityEntrance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/activity/activity")
);
const NewEntrance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/activity/New")
);
const EditEntrance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/activity/Edit")
);
const HistoryEntrance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/history/history")
);
const DetailEntrance = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/activity/Detail")
);
const propertiesMenuAccess = React.lazy(() =>
  import("../views/pages/accessibilityManagement/properties/properties")
);
const allDataTrain = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/attendance/dataTrain/dataTrain"
  )
);
const newDataTrain = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/attendance/dataTrain/addDataTrain"
  )
);
const detailHistoryAccess = React.lazy(() =>
  import("../views/pages/accessibilityManagement/entrance/history/detail")
);
const parkingHistory = React.lazy(() =>
  import("../views/pages/accessibilityManagement/parking/parkingHistory")
);
const parkingActivity = React.lazy(() =>
  import("../views/pages/accessibilityManagement/parking/activity/activity")
);
const newParkingActivity = React.lazy(() =>
  import("../views/pages/accessibilityManagement/parking/activity/new")
);
const editParkingActivity = React.lazy(() =>
  import("../views/pages/accessibilityManagement/parking/activity/edit")
);
const adminParkingFee = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/parking/activity/charge/adminCharge"
  )
);
const memberParkingFee = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/parking/activity/charge/memberCharge"
  )
);
const guestParkingFee = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/parking/activity/charge/guestCharge"
  )
);
const detailParkingActivity = React.lazy(() =>
  import("../views/pages/accessibilityManagement/parking/activity/detail")
);
const cashbackParking = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/parking/activity/charge/cashback"
  )
);

const activityPaygate = React.lazy(() =>
  import("../views/pages/accessibilityManagement/paygate/activity/activity")
);
const newActivityPaygate = React.lazy(() =>
  import("../views/pages/accessibilityManagement/paygate/activity/new")
);
const editActivityPaygate = React.lazy(() =>
  import("../views/pages/accessibilityManagement/paygate/activity/edit")
);
const historyActivityPaygate = React.lazy(() =>
  import("../views/pages/accessibilityManagement/paygate/history/history")
);
const subscriptionChoose = React.lazy(() =>
  import(
    "../views/pages/accessibilityManagement/subscripiton/chooseMenuSubscription"
  )
);
const subscripitonHistory = React.lazy(() =>
  import("../views/pages/accessibilityManagement/subscripiton/history/history")
);
const subscripitonConfiguration = React.lazy(() =>
  import("../views/pages/accessibilityManagement/subscripiton/config/config")
);

const accessibilityManagementRoutes = [
  {
    path: "/accessibility-management/device",
    element: listDevice,
  },
  {
    path: "/accessibility-management/device/detail/:id",
    element: detailDataDevice,
  },
  {
    path: "/accessibility-management/attendance/activity",
    element: activityAttendance,
  },
  {
    path: "/accessibility-management/attendance/activity/new",
    element: NewAttendance,
  },
  {
    path: "/accessibility-management/attendance/activity/:id",
    element: DetailAttendance,
  },
  {
    path: "/accessibility-management/attendance/activity/edit/:id",
    element: EditAttendance,
  },
  {
    path: "/accessibility-management/attendance/history",
    element: HistoryAbsentUser,
  },
  {
    path: "/accessibility-management/entrance/activity",
    element: ActivityEntrance,
  },
  {
    path: "/accessibility-management/entrance/activity/new",
    element: NewEntrance,
  },
  {
    path: "/accessibility-management/entrance/activity/edit/:id",
    element: EditEntrance,
  },
  {
    path: "/accessibility-management/entrance/history",
    element: HistoryEntrance,
  },
  {
    path: "/accessibility-management/entrance/activity/:id",
    element: DetailEntrance,
  },
  {
    path: "/accessibility-management/properties-menu",
    element: propertiesMenuAccess,
  },
  {
    path: "/accessibility-management/attendance/data-train",
    element: allDataTrain,
  },
  {
    path: "/accessibility-management/attendance/data-train/new",
    element: newDataTrain,
  },
  {
    path: "/accessibility-management/entrance/history/detail/:id",
    element: detailHistoryAccess,
  },
  {
    path: "/accessibility-management/parking/history",
    element: parkingHistory,
  },
  {
    path: "/accessibility-management/parking/activity",
    element: parkingActivity,
  },
  {
    path: "/accessibility-management/parking/activity/new",
    element: newParkingActivity,
  },
  {
    path: "/accessibility-management/parking/activity/edit/:id",
    element: editParkingActivity,
  },
  {
    path: "/accessibility-management/parking/activity/admin-parking-fee/:id",
    element: adminParkingFee,
  },
  {
    path: "/accessibility-management/parking/activity/member-parking-fee/:id",
    element: memberParkingFee,
  },
  {
    path: "/accessibility-management/parking/activity/guest-parking-fee/:id",
    element: guestParkingFee,
  },
  {
    path: "/accessibility-management/parking/activity/:id",
    element: detailParkingActivity,
  },
  {
    path: "/accessibility-management/parking/activity/cashback-parking/:id",
    element: cashbackParking,
  },

  {
    path: "/accessibility-management/paygate/activity",
    element: activityPaygate,
  },
  {
    path: "/accessibility-management/paygate/activity/new",
    element: newActivityPaygate,
  },
  {
    path: "/accessibility-management/paygate/activity/edit/:id",
    element: editActivityPaygate,
  },
  {
    path: "/accessibility-management/paygate/history",
    element: historyActivityPaygate,
  },
    {
    path: "/accessibility-management/parking/subscription",
    element: subscriptionChoose,
  },
  {
    path: "/accessibility-management/parking/subscription/history",
    element: subscripitonHistory,
  },
  {
    path: "/accessibility-management/parking/subscription/configuration",
    element: subscripitonConfiguration,
  },
];

export default accessibilityManagementRoutes;
